.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.115);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0px;
    .createdAt {
        margin-top: 3px;
    }
    .mesRef {
        margin-top: 5px;
        font-size: 12px;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        width: 100%;
        height: 30px;
        margin-top: 5px;
        input[type=button] {
            width: 80px;
            border: 1px solid;
            height: 25px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .errorIndicator {
        height: 13px;
        padding: 3px;
        color: white;
        user-select: none;
        align-self: flex-end;
        margin-left: auto;
        box-sizing: border-box;
        font-size: 7px;
        border-radius: 5px;
        background-color: red;
        margin-top: -20px;
        margin-bottom: 17px;
        z-index: 2000;
    }
    .dadosCliente {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: -10px;
        width: 100%;
        padding: 3px 10px;
        height: 20px;
        border-radius: 5px 5px 0px 0px;
        background-color: rgb(0, 72, 74);
        span {
            color: rgb(255, 255, 255);
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 20vw;
            display: inline-block;
            cursor: pointer;
        }
    }
}