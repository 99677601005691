.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    width: 25vw;
    border-radius: 5px;
    background-color: #0000000c;
    .header {
        user-select: none;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        h2 {
            margin: 0px;
            width: fit-content;
            font-weight: normal;
            font-size: 18px;
        }
    }
    .children {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 2px;
        flex-grow: 0;
        width: 100%;
        gap: 4px;
        box-sizing: border-box;
        padding: 4px;
    }
}