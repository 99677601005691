body {
    font-family: 'Source Sans Pro', 'Lucida Grande', sans-serif;
    margin: 0px;
    padding: 0px;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}