.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0;
    left: 0;
    .columns {
        width: 100vw;
        flex-grow: 1;
        display: flex;
        gap: 2px;
        justify-content: space-between;
        padding: 2px;
        box-sizing: border-box;
    }
}