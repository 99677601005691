.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    border-bottom: 1px solid;
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        box-sizing: border-box;
        padding: 16px;
        background-color: cadetblue;
        user-select: none;
        .h1 {
            justify-self: center;
            margin: 0px;
            margin-left: auto;
            margin-right: auto;
            padding: 0px;
            font-size: 18px;
            font-weight: normal;
        }
        button {
            width: 120px;
        }
        .loginButton {
            justify-self: flex-end;
            margin-left: auto;
        }
        .hideButton {
            justify-content: flex-start;
            margin-right: auto;
        }
    }
}